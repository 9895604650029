import { datadogRum } from '@datadog/browser-rum';
import 'dotenv/config';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from '../../store';
import * as React from 'react';

interface StateProps {
    clientToken: string | null;
    applicationId: string | null;
    environment: string | null;
}

type Props = StateProps;

class InitDataDogRum extends React.Component<Props> {
    componentDidMount() {
        const rumEnabled = this.props.clientToken && this.props.applicationId;
        if (typeof window !== 'undefined' && rumEnabled) {
            datadogRum.init({
                applicationId: this.props.applicationId || '',
                clientToken: this.props.clientToken || '',
                site: 'datadoghq.com', // Or 'datadoghq.eu' for EU
                service: 'merchant-dashboard', // Name of your application
                env: this.props.environment || 'development', // Environment name
                sessionSampleRate: 100, // Percentage of sessions to track (0-100)
                sessionReplaySampleRate: 100,
                defaultPrivacyLevel: 'mask-user-input', // Privacy settings
                allowedTracingUrls: ['https://dashboard-development.strongholdpay.com'], // Allow specific origins for tracing
            });
            datadogRum.startSessionReplayRecording();
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, null, ApplicationState> = (state) => ({
    applicationId: state.global.configuration.data_dog.rum.application_id,
    clientToken: state.global.configuration.data_dog.rum.client_token,
    environment: state.global.configuration.environment,
});

export default connect(mapStateToProps)(InitDataDogRum);
