import './styles/styles.scss';

import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthorizeRoute from './components/AuthorizeRoute';
import Developers from './pages/Developers';
import Settlements from './pages/Settlements';
import Paylink from './pages/Paylink/Paylink';
import CreatePayLink from './pages/Paylink/CreatePaylink';
import Paylinks from './pages/Paylink/Paylinks';
import Transactions from './pages/Transactions';
import { configuration, ROUTE, setAccessToken } from './config';
import { connect, MapStateToPropsParam } from 'react-redux';
import { ApplicationState } from './store';
import { isActionRequesting } from './store/selectors/global';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchConfiguration } from './store/actions';
import Home from './pages/Home';
import Customers from './pages/Customers';
import Layout from './components/Layout';
import Customer from './pages/Customer';
import Transaction from './pages/Transaction';
import Settlement from './pages/Settlement';
import Reports from './pages/Reports/Reports';
import Loader from './components/Loader';
import OutstandingReturnsReport from './pages/Reports/OutstandingReturns';
import TransactionsReport from './pages/Reports/Transactions';
import SettlementsReport from './pages/Reports/Settlements';
import SettlementDetailsReport from './pages/Reports/SettlementDetails';
import ActivityDetailsReport from './pages/Reports/ActivityDetails';
import { SPLIT_FLAG, USER_ROLE } from './store/types';
import Admin from './pages/Admin/Admin';
import AddUser from './pages/Admin/AddUser';
import AutomatedMailingReport from './pages/Reports/AutomatedMailing';
import { LoginCallback, Security } from '@okta/okta-react';
import { isAccessToken, OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { history } from './index';
import Promotions from './pages/Promotions';
import PromotionDetails from './components/promotions/PromotionDetails';
import InStore from './pages/InStore';
import InitDataDogRum from './components/datadog/InitDataDogRum';

interface StateProps {
    isRequesting: boolean;
    switching: boolean;
}

interface DispatchProps {
    fetchConfiguration: typeof fetchConfiguration;
}

type Props = StateProps & DispatchProps;

type State = {
    ready: boolean;
};

/* eslint-disable  @typescript-eslint/no-non-null-assertion */
class App extends React.PureComponent<Props, State> {
    oktaAuth?: OktaAuth;
    restoreOriginalUri?: (oa: OktaAuth, ou: string) => Promise<void>;

    public state: State = {
        ready: false,
    };

    async componentDidMount() {
        await this.props.fetchConfiguration();
        if (this.oktaAuth == undefined) {
            this.oktaAuth = new OktaAuth({
                issuer: configuration.oidc.authority,
                clientId: configuration.oidc.client_id,
                redirectUri: configuration.oidc.redirect_uri,
            });
            this.restoreOriginalUri = async (_oktaAuth, originalUri) => {
                history.replace(toRelativeUrl(originalUri || ROUTE.HOME, window.location.origin));
            };
        }

        // Listen for Okta Auth token renewal and replace old token with the new one
        this.oktaAuth.tokenManager.on('renewed', function (key, newToken) {
            if (isAccessToken(newToken)) {
                setAccessToken(newToken.accessToken);
            }
        });

        this.setState({ ready: true });
    }

    public render() {
        const { isRequesting, switching } = this.props;
        const { ready } = this.state;

        if (!ready || isRequesting) {
            return <div />;
        }

        if (switching) {
            return (
                <Layout>
                    <div className="d-flex justify-content-center m-md-5">
                        <Loader size="lg" />
                    </div>
                </Layout>
            );
        }

        return (
            <Security oktaAuth={this.oktaAuth!} restoreOriginalUri={this.restoreOriginalUri!}>
                <InitDataDogRum />
                <Switch>
                    <AuthorizeRoute path={ROUTE.IN_STORE} component={InStore} />
                    <AuthorizeRoute
                        userRole={USER_ROLE.MERCHANT_ALLUSERS}
                        path={ROUTE.DEVELOPERS}
                        component={Developers}
                    />
                    <AuthorizeRoute
                        userRole={USER_ROLE.MERCHANT_ALLUSERS}
                        path={ROUTE.TRANSACTION}
                        component={Transaction}
                    />
                    <AuthorizeRoute
                        userRole={USER_ROLE.MERCHANT_ALLUSERS}
                        path={ROUTE.TRANSACTIONS}
                        component={Transactions}
                    />
                    <AuthorizeRoute path={ROUTE.PROMOTION} component={PromotionDetails} />
                    <AuthorizeRoute path={ROUTE.PROMOTIONS} component={Promotions} />
                    <AuthorizeRoute
                        userRole={USER_ROLE.MERCHANT_ALLUSERS}
                        path={ROUTE.SETTLEMENT}
                        component={Settlement}
                    />
                    <AuthorizeRoute path={ROUTE.PAYLINK} component={Paylink} />
                    <AuthorizeRoute path={ROUTE.PAYLINK_CREATE} component={CreatePayLink} />
                    <AuthorizeRoute path={ROUTE.PAYLINKS} component={Paylinks} />
                    <AuthorizeRoute
                        userRole={USER_ROLE.MERCHANT_ALLUSERS}
                        path={ROUTE.SETTLEMENTS}
                        component={Settlements}
                    />
                    <AuthorizeRoute path={ROUTE.CUSTOMER} component={Customer} />
                    <AuthorizeRoute path={ROUTE.CUSTOMERS} component={Customers} />
                    <AuthorizeRoute
                        featureFlag={SPLIT_FLAG.TRANSACTIONS_REPORTS}
                        path={ROUTE.REPORTS_TRANSACTIONS}
                        component={TransactionsReport}
                    />
                    <AuthorizeRoute
                        featureFlag={SPLIT_FLAG.REPORTS_TAB}
                        path={ROUTE.REPORTS_OUTSTANDING_REPORTS}
                        component={OutstandingReturnsReport}
                    />
                    <AuthorizeRoute path={ROUTE.REPORTS_SETTLEMENTS} component={SettlementsReport} />
                    <AuthorizeRoute path={ROUTE.REPORTS_SETTLEMENT_DETAILS} component={SettlementDetailsReport} />
                    <AuthorizeRoute path={ROUTE.REPORTS_ACTIVITY_DETAILS} component={ActivityDetailsReport} />
                    <AuthorizeRoute path={ROUTE.REPORTS_AUTOMATED_MAILING} component={AutomatedMailingReport} />
                    <AuthorizeRoute featureFlag={SPLIT_FLAG.REPORTS_TAB} path={ROUTE.REPORTS} component={Reports} />
                    <AuthorizeRoute
                        userRole={USER_ROLE.MERCHANT_ALLADMIN}
                        path={ROUTE.ADMIN_ADD_USER}
                        component={AddUser}
                    />
                    {/*<AuthorizeRoute path={ROUTE.REWARDS} component={Rewards} />*/}
                    <AuthorizeRoute userRole={USER_ROLE.MERCHANT_ALLADMIN} path={ROUTE.ADMIN} component={Admin} />
                    <Route path={ROUTE.AUTH_CALLBACK} component={LoginCallback} />
                    <AuthorizeRoute loginIfNotAuthenticated={false} path={ROUTE.HOME} component={Home} />
                    <Redirect to={ROUTE.HOME} push />
                </Switch>
            </Security>
        );
    }
}

const mapStateToProps: MapStateToPropsParam<StateProps, void, ApplicationState> = (state) => ({
    isRequesting: isActionRequesting(state.global.actions, 'fetch_configuration'),
    switching: state.global.switchingContext,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            fetchConfiguration,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(App);
