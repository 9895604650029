import { merge } from 'lodash';
import { ApiEnvironment, ConfigurationModel } from './apis';
import * as Sentry from '@sentry/browser';
import * as DataDog from './datadog';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';

// Open ID authentication
export const configuration: ConfigurationModel = {
    sentry_dsn: '',
    environment: '',
    oidc: {
        authority: '',
        client_id: '',
        redirect_uri: '',
        post_logout_redirect_uri: '',
    },
    launch_darkly: {
        client_id: '',
    },
    split: {
        api_key: '',
    },
    data_dog: {
        client_token: '',
        site: '',
        environment: '',
        rum: {
            client_token: '',
            application_id: '',
        },
    },
    admin_uri: '',
    portal_url: '',
};

export function setConfiguration(newConfiguration: ConfigurationModel) {
    merge(configuration, newConfiguration);

    DataDog.initializeDataDog({
        environment: configuration.environment,
        clientToken: configuration.data_dog.client_token,
        site: configuration.data_dog.site,
    });

    if (configuration.sentry_dsn) {
        Sentry.init({
            dsn: configuration.sentry_dsn,
            environment: configuration.environment,
        });
    }
}

export const MANAGE_ACCOUNT_URI = '/enduser/settings';

// ========================================
// Router
export const ROUTE = {
    HOME: '/home',
    AUTH_CALLBACK: '/login/callback',
    CUSTOMER: '/customers/:customerId',
    CUSTOMERS: '/customers',
    TRANSACTIONS: '/transactions',
    TRANSACTION: '/transactions/:transactionId',
    SETTLEMENTS: '/settlements',
    SETTLEMENT: '/settlements/:settlementId',
    DEVELOPERS: '/developers',
    REPORTS: '/reports',
    REWARDS: '/rewards',
    ADMIN: '/admin',
    ADMIN_ADD_USER: '/admin/add-user',
    REPORTS_TRANSACTIONS: '/reports/transactions',
    REPORTS_OUTSTANDING_REPORTS: '/reports/outstanding-reports/:filterType',
    REPORTS_SETTLEMENTS: '/reports/settlements',
    REPORTS_SETTLEMENT_DETAILS: '/reports/settlement-details',
    REPORTS_ACTIVITY_DETAILS: '/reports/activity-details',
    REPORTS_AUTOMATED_MAILING: '/reports/optin-automated-mailing',
    REPORTS_VIEW_AUTOMATED_MAILING: '/reports/view-automated-mailing',
    PAYLINK_INDEX: '/paylink-index',
    PAYLINK_CREATE: '/paylink-create',
    PAYLINKS: '/paylinks',
    PAYLINK: '/paylink/:payLinkId',
    PROMOTION: '/promotions/:promotionId',
    PROMOTIONS: '/promotions',
    IN_STORE: '/in-store',
};

// ========================================
// Api environment
let apiEnvironment: ApiEnvironment = (localStorage.getItem('api-environment') as ApiEnvironment) || 'sandbox';
let accessToken: string = localStorage.getItem('access_token') || '';
let merchantId: string = localStorage.getItem('merchant_id') || '';

export function setApiEnvironment(environment: ApiEnvironment) {
    apiEnvironment = environment;
    localStorage.setItem('api-environment', environment);
}

export function getApiEnvironment() {
    return apiEnvironment;
}

export function setMerchantId(id: string) {
    merchantId = id;
    localStorage.setItem('merchant_id', merchantId);
}

export function getMerchantId() {
    return merchantId;
}

export function setAccessToken(token: string) {
    accessToken = token;
    localStorage.setItem('access-token', accessToken);
}

export function getAccessToken() {
    return accessToken;
}

// Okta
export interface OktaStateProps {
    oktaAuth: OktaAuth;
    authState: AuthState;
}
