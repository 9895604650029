import { request } from './utils';

export interface ConfigurationModel {
    environment: string;
    sentry_dsn: string;
    launch_darkly: {
        client_id: string;
    };
    split: {
        api_key: string;
    };
    data_dog: {
        client_token: string;
        site: string;
        environment: string;
        rum: {
            client_token: string;
            application_id: string;
        };
    };
    oidc: {
        authority: string;
        client_id: string;
        redirect_uri: string;
        post_logout_redirect_uri: string;
    };
    admin_uri: string;
    portal_url: string;
}

export async function getConfiguration() {
    const res = await request<ConfigurationModel>({
        method: 'GET',
        url: '/api/configuration',
    });
    return res.result;
}
